<template>
  <div>
    <b-button @click="openLicenceModal" variant="primary">
      Update licence</b-button
    >

    <b-modal id="modal-licence" title="Licence" hide-footer>
      <FormulateForm
        class="licence-form"
        v-model="formValues"
        @submit="submitLicence"
      >
        <FormulateInput
          name="full_name"
          type="text"
          label="Full name"
          placeholder="Full name"
          validation="required"
        />
        <FormulateInput
          name="license_number"
          type="text"
          label="License number"
          placeholder="License number"
        />
        <FormulateInput
          type="image"
          name="file"
          @file-removed="fileRemoved"
          label="Select an image to upload"
          help="Select a png, jpg or gif to upload."
          validation="mime:image/jpeg,image/png,image/gif"
        />
        <div class="row mb-3">
          <div class="col-sm-6">
            <FormulateInput
              name="expiry_date"
              type="date"
              label="Expiry date"
              placeholder="Expiry date"
            />
          </div>
          <div class="col-sm-6">
            <FormulateInput
              name="nationality"
              type="text"
              label="Nationality"
              placeholder="Nationality"
            />
          </div>
        </div>

        <div style="float: right">
          <FormulateInput
            type="submit"
            :label="uploading ? 'Uploading...' : 'Upload'"
          />
        </div>
      </FormulateForm>
    </b-modal>
  </div>
</template>

<script>
import { licence } from "@/config/api/licence";
export default {
  props: ["driver", "id"],
  data() {
    return {
      formValues: {
        full_name: "",
        license_number: "",
        file: [],
        expiry_date: "",
        nationality: "",
      },
      uploading: false,
    };
  },

  methods: {
    openLicenceModal() {
      if (this.driver) {
        this.formValues = {
          full_name: this.driver.full_name,
          license_number: this.driver.license_number,
          file: [
            {
              url: this.driver.license_front,
            },
          ],
          expiry_date: this.moment(this.driver.expiry_date).format(
            "YYYY-DD-MM"
          ),
          nationality: this.driver.nationality,
        };
      }

      this.$bvModal.show("modal-licence");
    },
    submitLicence() {
      const data = {
        full_name: this.formValues.full_name,
        expiry_date: this.moment(this.formValues.expiry_date).format(
          "MM-DD-YYYY"
        ),
        license_front:
          this.formValues.file.results[0].location ||
          this.formValues.file.results[0].url,

        license_number: this.formValues.license_number,

        nationality: this.formValues.nationality,
      };

      const api = licence.updateLicence;
      api.id = this.id;
      api.data = data;

      this.uploading = true;
      this.generateAPI(api)
        .then((res) => {
          this.$bvToast.toast("Licence update successfully!", {
            title: "Licence update",
            variant: "success",
            solid: true,
          });
          this.$bvModal.hide("modal-licence");
          this.$emit("change", res.data.user.license);
        })
        .catch((err) => {
          this.$bvToast.toast(
            `Licence is not update successfully! ${err.response.data.error}`,
            {
              title: "Licence update",
              variant: "danger",
              solid: true,
            }
          );
        })
        .finally(() => {
          this.uploading = false;
        });
    },
    fileRemoved() {
      this.formValues.file = [];
    },
  },
};
</script>

<style></style>
