<template>
  <div>
    <b-button @click="copyLocation" variant="primary" size="sm" class="p-0 ml-2"><i class="ri-pencil-line"></i>Copy
      Location</b-button>
    <b-modal id="copy-location-modal" title="Copy Location" hide-footer @hidden="$emit('resetModal')">
      <FormulateForm class="flat-rate-form" @submit="copy_location" v-model="formValues">
          <div class="pb-2">Select Driver</div>
          <multiselect :multiple="false" :searchable="true" :max="40" :maxHeight="400" track-by="name"
          :internalSearch="false"
          v-on:search-change="onSearchUser" 
           label="name" :options="drivers" v-model="refDriverId">
          </multiselect>
          <div v-if="showError" style="color: darkred">Required</div>
        <FormulateInput type="date" name="startDate" v-model="startDate" label="Start date" validation="optional" />
        <FormulateInput type="date" name="endDate" v-model="endDate" label="End date" validation="optional" />
        <div class="text-right">
          <FormulateInput type="submit" :label="changing ? 'Copying Location...' : 'Copy Location'" />
        </div>
      </FormulateForm>
    </b-modal>
  </div>
</template>
  
<script>
import { troubleshoot } from "@/config/api/troubleshoot";
import Multiselect from "vue-multiselect";
import { driver } from "@/config/api/drivers";

export default {
  props: ["data", "id", "startDate", "endDate"],
  data() {
    return {
      formValues: {},
      changing: false,
      refDriverId: null,
      currentPage:1,
      driverData: [],
      isBusy: false,
      showError: false,
    };
  },

  components: {
    Multiselect
  },

  computed: {
 

    drivers() {
      return this.driverData ? this.driverData.map((item) => {
        return {
          name: item.phone_number,
          label: item._id,
        };
      })
        : [];
    }

  },
  methods: {
    copyLocation() {
      this.$bvModal.show("copy-location-modal");
    },

    onSearchUser(query){
      console.log("onSearchUser",query)
      this.currentPage = 1;
      this.getAllUser(
        query 
      );
    },

    getAllUser(search = "") {
      this.isBusy = true;
      const api = driver.get;
      api.params = { page: this.currentPage, search: search};
      this.generateAPI(api)
        .then((res) => {
          this.driverData = res.data.drivers;
          this.isBusy = false;
        })
        .catch((err) => {
          console.error(err.response.data);
        });
    },
    copy_location() {
      if(this.refDriverId == null) {
       this.showError = true;
      }
      console.log("data", this.id);
      console.log("ref", this.refDriverId);
      const api = troubleshoot.copy_driver_location;
      this.changing = this.showError ? false : true;
      api.data = {
        "driverId": this.id,
        "refDriverId": this.refDriverId.label,
        "startDate": this.startDate,
        "endDate": this.endDate,
      }
      this.generateAPI(api).then((res) => {
        console.log("res", res);
        this.$bvToast.toast("Copied Location successfully!", {
          title: "Copy Location",
          variant: "success",
          solid: true,
        });

        this.$bvModal.hide("copy-location-modal");
        this.$emit("resetModal")
        this.refDriverId = null;
      })
        .catch((err) => {
          this.$bvToast.toast(
            `Location is not copied!, ${err.response.data.error}`,
            {
              title: "Copy Location",
              variant: "danger",
              solid: true,
            }
          );
        })
        .finally(() => {
          this.changing = false;
        });

    },
   

  },
};
</script>
  
<style>
</style>
  